/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"

import "./layout.css"

const Layout = ({ children, noHeader, noFooter }) => {

// const data = useStaticQuery(graphql`
//     query SiteTitleQuery {
//       site {
//         siteMetadata {
//           title
//         }
//       }
//     }
//   `)





  return (
    <>
        { !noHeader &&
            <Header />
        }
        <main>{children}</main>
        
        { !noFooter &&

            <Footer />
        }


    </>
  )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
