import { Link } from "gatsby"
import React from "react"

const Footer = () => (
  <footer>


    <div className="row row___max">
        <div className="col col4">
            <h2 className="logo"><Link to="/">— Polygon Dynamics</Link></h2>
            <br></br>
            <p>Copyright by Gaspard+Bruno Ltd, 2021. All rights reserved. All materials on this website are intellectual property of Gaspard+Bruno Ltd.</p>
            <p>Texts and photos present on this site are not royalty free. All rights for representation and reproduction reserved.</p>
            <br></br>
            <p><a style={{color: "white"}} href="https://gaspardbruno.com" rel="noopener" target="_blank">Gaspard + Bruno</a> ©{new Date().getFullYear()}</p>
        </div>

        <div className="col col4">
            {false &&
              <p><a style={{color: "white"}} href="/" rel="noopener" target="_blank">En</a> / <a style={{color: "white"}} href="/" rel="noopener" target="_blank">Fr</a></p>
            }
        </div>
    </div>
  </footer>
)



export default Footer
