import { Link } from "gatsby"
import React, { Component } from "react"

import AniLink from "gatsby-plugin-transition-link/AniLink";

class Header extends Component {



    state = {
        scrollingClass: '',
            mouseMove: ''
    }

    lastScrollPosition = 0

    handleScroll = (event) => {
        const newScrollPosition = typeof window !== `undefined` ? window.scrollY : 0

        if (newScrollPosition > this.lastScrollPosition) {
            if (this.state.scrollingClass !== 'down') {
                this.setState({
                    scrollingClass: 'down',
                    mouseMove: ''
                 })

            }
        } else {
            if (this.state.scrollingClass !== 'up') {
                this.setState({
                    scrollingClass: 'up',
                    mouseMove: ''
                 })
            }
        }

        this.lastScrollPosition = newScrollPosition
     }

    componentDidMount() {
      try {

          if (typeof window !== `undefined`) {
            window.hello=() => {document.location.href = "mailto:hello@gaspardbruno.com";console.log("Send us an email!");return "";}
            setTimeout(console.log.bind(console, "Hello, you're interested in what's going on behind? We'd love to hear from you at " + '%chello@gaspardbruno.com!' + '%c -> ' + '%chello()', 'color: #1812d6', 'color:black', 'font-weight:bold'), 0);
          }

        //
        //
        // UIkit.sticky('header', {
        //       offset: 0
        // });
        if (typeof window !== `undefined`) {
          window.addEventListener('scroll', this.handleScroll, { passive: true })

          window.addEventListener('mousemove', e => {
                      this.setState({
                          scrollingClass: '',
                          mouseMove: 'mouseMove'
                       })
          });
        }

      } catch (e) {
        console.error(e)
      }
    }

    componentWillUnmount() {
      if (typeof window !== `undefined`) {
        window.removeEventListener('scroll', this.handleScroll)
      }
    }

    hideLightbox() {
        try {
          const UIkit = require("uikit/dist/js/uikit")
          if (typeof UIkit !== `undefined` && UIkit.lightbox('.lightbox')) {
              UIkit.lightbox('.lightbox').hide()
          }
          if (typeof UIkit !== `undefined` && UIkit.lightbox('.polygonDynamicsList')) {
              UIkit.lightbox('.polygonDynamicsList').hide()
          }
        } catch (e) {
          console.error(e)
        }
    }

    render() {
        return(
            <header onClick={this.sayHello} className={`${this.state.scrollingClass} ${this.state.mouseMove}`}>
                <div className="row row___max">
                    <div className="col col4">
                        <h1 className="logo"><AniLink onClick={() => this.hideLightbox()} fade duration={0.5} to="/">— Polygon Dynamics</AniLink></h1>
                    </div>
                    <div className="col col4">
                        <div className="row">
                            {true &&
                              <>
                                <p className="menuItem hidden_iphonePlus"><AniLink onClick={() => this.hideLightbox()} fade duration={0.5} to="/archive">Archive</AniLink></p>
                              </>
                            }
                            <p className="menuItem">~</p>
                            <p className="menuItem"><AniLink onClick={() => this.hideLightbox()} fade duration={0.5} to="/fashion">Fashion</AniLink></p>
                            <p className="menuItem"><AniLink onClick={() => this.hideLightbox()} fade duration={0.5} to="/services">Services</AniLink></p>
                            <p className="menuItem"><AniLink onClick={() => this.hideLightbox()} fade duration={0.5} entryoffset={80} to="/information">Information</AniLink></p>
                        </div>
                    </div>
                </div>
            </header>
        )
    }

}


export default Header
